const openregisterLocationPicker = require('govuk-country-and-territory-autocomplete');

/**
 * @param includeUk: if true, ensure that includeUk is true in the call to getNationalitiesForAutocomplete in the lambda
 */

exports.nationalityAutocomplete = function countryAutocomplete(assetRoot, includeUk) {
    const selects = document.getElementsByClassName("nationality-autocomplete");

    for (let i = 0; i < selects.length; i++) {
        openregisterLocationPicker({
            selectElement: selects[i],
            url: `${assetRoot}/shared/js/nationalities-autocomplete-graph.json`,
            additionalEntries: includeUk ? ukEntries : [],
            additionalSynonyms: includeUk ? ukSynonyms : []
        });
    }

    fixClearingSelects(selects);
    copyClassesFromSelectToAutoComplete(selects);
};

/**
 * @param includeUk: if true, ensure that includeUk is true in the call to getCountriesOfResidenceForAutocomplete in the lambda
 */

exports.countryOfResidenceAutocomplete = function countryOfResidenceAutocomplete(assetRoot, includeUk) {
    const selects = document.getElementsByClassName("country-of-residence-autocomplete");

    for (let i = 0; i < selects.length; i++) {
        openregisterLocationPicker({
            selectElement: selects[i],
            url: `${assetRoot}/shared/js/countries-of-residence-autocomplete-graph.json`,
            additionalEntries: includeUk ? ukEntries : [],
            additionalSynonyms: includeUk ? ukSynonyms : []
        })
    }

    fixClearingSelects(selects);
    copyClassesFromSelectToAutoComplete(selects);
}

exports.setAutocompleteEmpty = function setAutocompleteEmpty(autocompleteId) {
    // Remove value from input
    $('input[id=' + autocompleteId + ']').val("");
    // Remove value from hidden select
    $('select[id=' + autocompleteId + '-select]').val("");
};

function fixClearingSelects(selects) {
    // Fixes bug in accessible autocomplete where clearing field with delete/cut etc
    // doesn't clear underlying select element.
    Array.from(selects).forEach((select) => {

        // Progressive Enhancement hides the select element and appends "-select" to its id.
        // A text input is created with the original ID.
        const selectId = select.id;
        const inputId = selectId.substring(0, selectId.indexOf("-select"));

        $(`input[type=text][id=${inputId}]`).on("blur change", function (event) {
            if (!event.target.value) {
                exports.setAutocompleteEmpty(event.target.id);
            }
        });
    });
}

function copyClassesFromSelectToAutoComplete(selects) {
    // The gov govuk-country-and-territory-autocomplete and accessible-autocomplete
    // wrappers do not copy over our width classes from the select to the completing
    // input.
    // We can't fix this by wrapping the whole input group in a width class as that
    // would unnecessarily narrow any long error messages.
    Array.from(selects).forEach((selectElt) => {
        if (selectElt.classList) {
            const selectId = selectElt.id;
            const match = /^(.*)-select$/.exec(selectId);
            if (match !== null) {
                const inputId = match[1];
                const autocompleteInput = document.getElementById(inputId);
                const autocompleteWrapper = autocompleteInput
                    && autocompleteInput.closest
                    && autocompleteInput.closest(".autocomplete__wrapper");

                if (autocompleteWrapper && autocompleteWrapper.classList) {
                    for (let j = 0; j < selectElt.classList.length; j++) {
                        const clazz = selectElt.classList[j];
                        if (/-autocomplete$/.test(clazz) || "govuk-select" === clazz) {
                            // skip marker classes
                        } else {
                            // The classes will be error or width classes, need to go on the wrapper
                            // so they don't get dropped when the input class gets modified at runtime
                            autocompleteWrapper.classList.add(clazz);
                        }
                    }
                }
            }
        }
    });
}

/**
 * At build time we filter the nationalities used by the country select and remove all entries that are not found in nationalities.ts (see prepareLocationAutocomplete.ts)
 * However /overseas/passport/details should include the UK so here we add it back in.
 * The list of synonyms is copied from node_modules/govuk-country-and-territory-autocomplete/dist/location-autocomplete-graph.json
 */

const ukEntries = [
    { name: "United Kingdom (including the Channel Islands or Isle of Man)", code: 'country:GB'}
];

const ukSynonyms = [
    { name: "An Rywvaneth Unys", code: 'country:GB' },
    { name: "Bitain", code: 'country:GB' },
    { name: "Briain", code: 'country:GB' },
    { name: "Britain", code: 'country:GB' },
    { name: "Britiain", code: 'country:GB' },
    { name: "Brtain", code: 'country:GB' },
    { name: "Englsnd", code: 'country:GB' },
    { name: "GB", code: 'country:GB' },
    { name: "Grat Britain", code: "country:GB" },
    { name: "Great Britan", code: "country:GB" },
    { name: "Gret Britain", code: "country:GB" },
    { name: "Rìoghachd Aonaichte", code: "country:GB" },
    { name: "Ríocht Aontaithe", code: "country:GB" },
    { name: "Scottland", code: "country:GB" },
    { name: "U.K.", code: "country:GB" },
    { name: "UK", code: "country:GB" },
    { name: "United Kingdom of Great Britain and Northern Ireland", code: "country:GB" },
    { name: "Unitit Kinrick", code: "country:GB" },
    { name: "Y Deyrnas Unedig", code: "country:GB" },
    { name: "England", code: "country:GB" },
    { name: "Scotland", code: "country:GB" },
    { name: "Wales", code: "country:GB" },
    { name: "Northern Ireland", code: "country:GB" }
];