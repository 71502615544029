// Prevent multiple form submissions by pressing enter or clicking the submit button
// The govukButton component has a preventDoubleClick property, but this only debounces in a one-second window,
// and is therefore not sufficient for our needs

const forms = document.querySelectorAll('form');
// only take the last form on the page, as document upload pages can have multiple forms on them, and this could
// otherwise interfere with their behavior. The 'continue' button is always in the last form on a page.
const lastForm = forms[forms.length - 1];

let hasSubmitted = false;
lastForm && lastForm.addEventListener('submit', (e) => {
    if (hasSubmitted) {
        // prevent multiple form submissions by keyboard
        e.preventDefault();
    }
    hasSubmitted = true;

    const buttons = document.querySelectorAll('.govuk-button');
    buttons.forEach(button => {
        button.setAttribute('disabled', '');
        button.setAttribute('aria-disabled', true);
    });
});