exports.confirmation = function() {
    $('.change-link').click(function () {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/confirmation',
            eventAction: 'Change link clicked'
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': 'change_link_clicked'
            },
        })
    });

    $('input[name="js-enabled"]').val("true");
};
