/**
 * This file is similar to:
 *    - photo-upload.js
 *    - upload-documents.js
 *    - upload-documents-pre-selected.js
 *  Bugs found here likely have to be fixed there too.
 *  Possibility of commonizing these in the future.
 *
 *  This file (upload-documents-pre-selected.js) is currently used by:
 *    - VAC, Postal and Proxy "pre selected upload"
 *      frontend/src/forms/shared/upload-documents-pre-selected/upload-documents-pre-selected.njk
 */

import '../components/loader';
import {urlToBucketKey as s3UrlToBucketKey} from "../s3";

exports.uploadDocumentsPreSelected = function (
    docTypesToUpload,
    maxFileSizeInMB,
    minFileSizeInKB,
    acceptedFileExtensions,
    error__none_selected,
    error__too_big,
    error__too_small,
    error__filetypes,
    error__unknown,
    error__abort,
    screen_reader_alert__file_added,
    screen_reader_alert__file_removed,
    allowPartialCompletion,
    button_text__continue,
    error_phrase,
) {
    // TODO:EIP1-7498 add something like preventDropFilesExceptOnDropArea();
    // should also allow drop files onto the whole card for each file type
    addScreenReaderFileStatusAlert();

    const continueForm = document.getElementById("continue-form");
    const nextButton = continueForm.querySelector('button[name="submit-button"]');

    disableNextButtonOnRemoveFile();

    // the js code only runs while still uploading
    if (docTypesToUpload.length === 0) {
        return;
    }

    const errorMessages = {
        error__none_selected,
        error__too_big,
        error__too_small,
        error__filetypes,
        error__unknown,
        error__abort
    };

    const spinner = new GOVUK.Loader();
    enableNextButton();

    // containers
    for (const type of docTypesToUpload) {
        const fileUpload = document.getElementById('file-upload-' + type);
        const uploadForm = document.getElementById('upload-form-' + type);
        const uploadFormOrFilePreviewElt = document.getElementById('upload-form-or-file-preview-' + type);
        const uploadButton = uploadForm.querySelector(`button[name="upload-button"]`);
        const formComponents = {
            type,
            uploadBoxId: 'upload-box-' + type,
            uploadForm,
            uploadButtonId: 'upload-button-' + type,
            fileUpload,
            spinnerId: 'spinner-container-id-' + type,
            uploadFormOrFilePreviewElt,
            fileHasBeenUploadedOk: false
        }

        fileUpload.classList.add("hidden");
        fileUpload.addEventListener('change', (event) => uploadFile(event, formComponents));
        uploadButton.addEventListener('click', () => fileUpload.click());
        uploadButton.id = formComponents.uploadButtonId;
        uploadButton.type = "button";
    }

    // error components
    const errors = {};
    const jsErrorSummary = document.querySelector(".govuk-error-summary");
    const jsErrorList = jsErrorSummary.querySelector("ul");

    function uploadFile(event, formComponents) {
        event.preventDefault();

        disableNextButton();
        setDisabledForOtherUploadButtons(formComponents.type, true);

        clearErrors();
        formComponents.uploadForm.classList.add("govuk-visually-hidden");
        spinner.init({
            container: formComponents.spinnerId,
            styleElementId: "loading-spinner-styles",
            label: true
        });

        const file = formComponents.fileUpload.files[0];
        const fileIsValid = validateFile(file, formComponents.uploadButtonId);

        if (!fileIsValid) {
            renderErrorAndResetPageControls(formComponents);
            return false;
        }

        // testing browser support. if no support for the required js APIs
        // the form will just be posted naturally with no progress showing.
        var xhr = new XMLHttpRequest();
        if (!(xhr && ('upload' in xhr) && ('onprogress' in xhr.upload)) || !window.FormData) {
            formComponents.uploadForm.submit();
            return;
        }

        xhr.upload.onprogress = progressHandler;
        xhr.onerror = (data) => errorHandler(data, formComponents);
        xhr.onabort = () => abortHandler(formComponents);
        xhr.onload = s3UploadCompleteHandler.bind(this, xhr, formComponents, file.name);

        const formData = new FormData(formComponents.uploadForm);
        // set to "" so not redirected automatically
        formData.set("success_action_redirect", "");
        xhr.open('POST', formComponents.uploadForm.action);
        xhr.send(formData);

        return false;
    }

    function validateFile(file, uploadButtonId) {
        if (!file) {
            errors[uploadButtonId] = errorMessages.error__none_selected;
            return false;
        }
        if (file.size > maxFileSizeInMB * 1000000) {
            errors[uploadButtonId] = errorMessages.error__too_big;
            return false;
        }
        if (file.size < minFileSizeInKB * 1000) {
            errors[uploadButtonId] = errorMessages.error__too_small;
            return false;
        }
        const nameLower = file.name.toLowerCase();
        if (!acceptedFileExtensions.find(ext => nameLower.endsWith(ext))) {
            errors[uploadButtonId] = errorMessages.error__filetypes;
            return false;
        }
        clearErrorSummaryItems();
        return true;
    }

    function progressHandler(e) {
        var progress = Math.floor((e.loaded / e.total) * 100);
        spinner.updateMessage(`Uploading... ${progress}%`);
        spinner.updateProgress(progress);
    }

    // Called when the upload to S3 has completed
    function s3UploadCompleteHandler(uploadXhr, formComponents, fileName) {
        try {
            spinner.updateMessage(`Processing`);
            if (uploadXhr.status !== 204) {
                // 'xhr.onerror' is only called if there's an error at the network level.
                // If the error only exists at the application level (e.g. an HTTP error code is sent), it will not be called.
                // So we need to check the status code here in order to show a validation message if s3 reject the file.
                throw new Error("Error status from S3: " + uploadXhr.status);
            }

            // Tell the backend the name of the file that was just uploaded,
            // so the session state can be updated.
            // This allows us to avoid S3 "list" ops, which don't scale to high load.
            //
            // If the client browser fails or goes offline between the above op and
            // this then the image info will not get added to the session, and they
            // will need to upload it again

            // S3 can change the provided filename, for example spaces are trimmed.
            // We therefore get the final bucket + key info from the Location header
            const s3Location = uploadXhr.getResponseHeader("location");
            const bucketKey = s3UrlToBucketKey(s3Location);

            // browser xhr support was already tested above
            var xhr = new XMLHttpRequest();

            xhr.onload = sessionUpdateCompleteHandler.bind(this, xhr, formComponents);
            xhr.onerror = (e) => errorHandler(e, formComponents);
            xhr.onabort = () => abortHandler(formComponents);

            xhr.open('POST', document.location);
            const formData = {
                "s3-upload-complete": "true",
                bucket: bucketKey.bucket,
                key: bucketKey.key
            };
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            // ask the browser to parse the html response into a DOM
            xhr.responseType = "document";
            xhr.send(new URLSearchParams(Object.entries(formData)).toString());

        } catch (e) {
            errorHandler(e, formComponents)
        }
    }

    // Called when the after-upload session update XHR has completed
    function sessionUpdateCompleteHandler(xhr, formComponents) {
        try {
            if (xhr.status !== 200) {
                // 'xhr.onerror' is only called if there's an error at the network level.
                // If the error only exists at the application level (e.g. an HTTP error code is sent), it will not be called.
                // So we need to check the status code here in order to show a validation message if s3 reject the file.
                throw new Error("Error status from backend: " + xhr.status);
            }

            const newHtml = xhr.responseXML;
            if (!newHtml) {
                throw new Error("responseXML null. Not supported browser?");
            }
            const elt = formComponents.uploadFormOrFilePreviewElt
            elt.replaceChildren(...newHtml.body.childNodes)

            resetPageControls();
            setDisabledForOtherUploadButtons(formComponents.type, false);
            formComponents.fileHasBeenUploadedOk = true;
            screenReaderFileAlert(screen_reader_alert__file_added);
            allowPartialCompletion && updateContinueButtonText();
            disableNextButtonOnRemoveFile();
        } catch (e) {
            errorHandler(e, formComponents)
        }
    }

    function errorHandler(data, formComponents) {
        console.error(data);
        errors[formComponents.uploadBoxId] = errorMessages.error__unknown;
        renderErrorAndResetPageControls(formComponents);
    }

    function abortHandler(formComponents) {
        errors[formComponents.uploadBoxId] = errorMessages.error__abort;
        renderErrorAndResetPageControls(formComponents);
    }

    function renderErrors(uploadForm) {
        spinner.stop();
        uploadForm.classList.remove("govuk-visually-hidden");

        Object.entries(errors).forEach(([fieldId, message]) => {
            addErrorSummaryItem(fieldId, message);
            addErrorToField(fieldId, message);
        });

        jsErrorSummary.style.display = 'block';
        jsErrorSummary.focus();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    function clearErrorSummaryItems() {
        // Some pages have an error summary which is hidden by default, some don't. Setting to none works in both cases.
        jsErrorSummary.style.display = 'none';
        jsErrorList.childNodes.forEach(function (child) {
            jsErrorList.removeChild(child)
        })
    }

    function clearErrors() {
        Object.keys(errors).forEach(fieldId => {
            jsErrorList.removeChild(document.getElementById(fieldId + "--top-error"))
            removeErrorFromField(fieldId);
            delete errors[fieldId];
        });
    }

    function addErrorSummaryItem(fieldId, errorMessage) {
        const listItem = document.createElement("li");
        const anchor = listItem.appendChild(document.createElement("a"));
        anchor.href = "#" + fieldId;
        anchor.textContent = errorMessage;

        listItem.id = fieldId + "--top-error"
        jsErrorList.appendChild(listItem);
    }

    function addErrorToField(fieldId, errorMessage) {
        const field = document.getElementById(fieldId);
        const container = field.parentNode;
        const spanId = field.id + "--span-error";

        let span = document.getElementById(spanId);
        if (span && span.lastChild) {
            span.lastChild.textContent = errorMessage;
        } else {
            span = document.createElement("span");
            span.id = spanId;
            span.className = 'govuk-error-message';
            var hiddenSpan = span.appendChild(document.createElement("span"));
            hiddenSpan.className = "govuk-visually-hidden";
            hiddenSpan.textContent = `${error_phrase}:`;
            var errorSpan = span.appendChild(document.createElement("span"));
            errorSpan.textContent = errorMessage;
        }

        container.insertBefore(span, field);
        container.classList.add("govuk-form-group--error");
    }

    function removeErrorFromField(fieldId) {
        const field = document.getElementById(fieldId);
        const container = field.parentNode;
        const spanId = field.id + "--span-error";
        const span = document.getElementById(spanId);

        if (span) {
            container.removeChild(span);
            container.classList.remove("govuk-form-group--error");
        }
    }

    function renderErrorAndResetPageControls(formComponents) {
        resetPageControls();
        formComponents.fileUpload.value = "";
        renderErrors(formComponents.uploadForm);
        setDisabledForOtherUploadButtons(formComponents.type, false);
        updateTitleIfNotAlreadyInError();

        jsErrorSummary.style.display = 'block';
        jsErrorSummary.focus();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    function updateTitleIfNotAlreadyInError() {
        const title = document.title;
        if (title && !title.startsWith(`${error_phrase}:`)) {
            document.title = `${error_phrase}: ${title}`;
        }
    }

    // Hide the spinner and re-enable the next button
    function resetPageControls() {
        spinner.stop();
        enableNextButton();
        clearErrorSummaryItems();
    }

    function enableNextButton() {
        nextButton.removeAttribute("disabled");
        nextButton.removeAttribute("aria-disabled");
    }

    function disableNextButton() {
        nextButton.setAttribute("disabled", "");
        nextButton.setAttribute("aria-disabled", true);
    }

    function setDisabledForOtherUploadButtons(currentType, value) {
        for (const type of docTypesToUpload.filter(docType => docType !== currentType)) {
            const uploadForm = document.getElementById('upload-form-' + type);
            if (uploadForm){
                uploadForm.querySelector(`button[name="upload-button"]`).disabled = value;
            }
        }
    }

    function addScreenReaderFileStatusAlert() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (urlSearchParams.get("file-removed") === "true") {
            screenReaderFileAlert(screen_reader_alert__file_removed);
        }

        // no "file-added" case, as that's either handled directly in js,
        // see screen_reader_alert__file_added above, or we're no-js
    }

    function screenReaderFileAlert(alertMessage) {
        const alert = document.getElementById("hidden-alert-container");
        alert.innerHTML = alertMessage;
    }

    function updateContinueButtonText() {
        // No need to update button on file removal, as page refreshes
        nextButton.innerHTML = button_text__continue
    }

    function disableNextButtonOnRemoveFile() {
        const removeFileButtons = document.getElementsByName('remove-file-button');
        removeFileButtons && removeFileButtons.forEach(button => {
            button.addEventListener('click', () => {
                disableNextButton();
            });
        });
    }
};