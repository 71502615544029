exports.contact = function() {
    $('#contact-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventMultipleContactMethods(formData);
    });
};

function gaEventMultipleContactMethods(formData) {
    const numContactMethods = formData.getAll('contact').length;

    if (numContactMethods > 1) {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/contact',
            eventAction: 'Multiple contact methods provided',
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': 'multiple_contact_methods_provided'
            },
        })
    }
}