const setAutocompleteEmpty = require('./country-autocomplete').setAutocompleteEmpty;

exports.nationalityButtons = function nationalityButtons () {
    const $removeCountryFieldButton = $('.remove-country-field');
    const $addAdditionalCountryFieldButton = $('.add-additional-country');

    // Configure 'remove' buttons
    $removeCountryFieldButton.click(function (event) {
        event.preventDefault();
        var wrapper = $(event.currentTarget).closest('.additional-country-form-group');
        var autocompleteId = wrapper.find('input:text').attr('id');
        setAutocompleteEmpty(autocompleteId);
        wrapper.hide();
        updateButtonVisibility();
    });

    // Configure 'add country' button
    $addAdditionalCountryFieldButton.click(function (event) {
        event.preventDefault();
        const firsthidden = getHiddenCountryFields().first();
        firsthidden.parent().append(firsthidden);
        firsthidden.show();
        updateButtonVisibility();
        firsthidden.find('input').focus();
    });

    // Set initial display states
    function hideIfEmpty(element) {
        var $element = $(element);
        if (!$element.val()) {
            $element.closest('.additional-country-form-group').hide();
        }
    }

    $('#added-country-2, #added-country-3')
        .each(function (_, element) {
            return hideIfEmpty(element)
        });

    $removeCountryFieldButton.hide();

    updateButtonVisibility();

    function updateButtonVisibility() {
        switch (getHiddenCountryFields().length) {
            case 0:
                $addAdditionalCountryFieldButton.hide();
                $removeCountryFieldButton.show();
                break;
            case 2:
                $addAdditionalCountryFieldButton.show();
                $removeCountryFieldButton.hide();
                break;
            default:
                $addAdditionalCountryFieldButton.show();
                $removeCountryFieldButton.show();
        }
    }

    function getHiddenCountryFields() {
        return $('#different-country-html').find('.additional-country-form-group:hidden');
    }
};


