exports.dateOfBirth = function dateOfBirth() {
    $('#dob-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventContactEmail(formData);
        gaEventDontKnowDob(formData);
    });
};

function gaEventDontKnowDob(formData) {
    if (formData.get('reason-not-provided')) {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/date-of-birth',
            eventAction: "User doesn't know DOB"
        });
    }
}

function gaEventContactEmail(formData) {
    if (formData.get('contact-email')) {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Email Address Collection',
            eventAction: 'DOB Email',
            eventLabel: 'DOB - Email Address Provided'
        });
    }
}