exports.manualAddress = function manualAddress() {
    const countyField = $('#county');
    const localAuthorityField = $("#local-authority");
    const nationField = $('#nation');

    const allLocalAuthorityOptions = localAuthorityField.html();
    filterLocalAuthoritiesByCounty();

    countyField.on('change', filterLocalAuthoritiesByCounty);


    filterLocalAuthoritiesByNation();
    nationField.on('change', filterLocalAuthoritiesByNation);

    function filterLocalAuthoritiesByCounty() {
        if (!countyField.length) {
            return;
        }
        const county = countyField.val();
        localAuthorityField.html(allLocalAuthorityOptions);
        if (county !== 'DEFAULT') {
            $("#local-authority option").filter(function () {
                const item = $(this);
                return item.data('county') !== county && item.val() !== 'DEFAULT';
            }).remove();
        }
    }

    function filterLocalAuthoritiesByNation() {
        if (!nationField.length) {
            return;
        }
        const nation = nationField.val();
        localAuthorityField.html(allLocalAuthorityOptions);
        if (nation !== 'DEFAULT') {
            $("#local-authority option").filter(function() {
                const item = $(this);
                if (nation === 'Scotland') {
                    return item.data('county') !== 'SCOTTISH' && item.val() !== 'DEFAULT';
                }
                if (nation === 'Wales') {
                    return item.val() !== 'DEFAULT' && !item.data('gsscode').startsWith('W');
                }
                return item.val() !== 'DEFAULT' && !item.data('gsscode').startsWith('E');
            }).remove();
        }
    }
};
