exports.name = function() {
    $('#name-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventMiddleName(formData);
    });
};

function gaEventMiddleName(formData) {
    if (formData.get('middle-names')) {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/name',
            eventAction: "Middle name entered"
        });
    }
}