exports.addressAnalytics = function () {
    $('#manual-entry-button').click(function() {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Postcode not found',
            eventAction: 'Postcode not found in OS Places/ViaEuropa'
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': 'postcode_not_found'
            },
        })
    })
};
