exports.nino = function nino() {
    $('#nino-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventContactEmail(formData);
        gaEventDontKnowNino(formData);
    });
};

function gaEventDontKnowNino(formData) {
    if (formData.get('reason-not-provided')) {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/nino',
            eventAction: 'NINo unknown',
        });
    }
}

function gaEventContactEmail(formData) {
    if (formData.get('contact-email')) {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Email Address Collection',
            eventAction: 'NINO Email',
            eventLabel: 'NINO - Email Address Provided'
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': 'unknown_ni_number'
            },
        })
    }
}