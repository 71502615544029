exports.getCookie = getCookie;
exports.hideCookieBanner = hideCookieBanner;

// Setup the cookie banner UI
exports.cookieBanner = () => {
    if (window.IER_HIDE_COOKIE_BANNER) {
        // Hide the banner on the cookies page, as it duplicates the page pattern.
        // This pattern is used on https://www.gov.uk/help/cookies
        return;
    }

    if (getCookie("cookie_preferences_set") === undefined) {
        // Cookie banner hidden for non-js users - see 'Option 3'
        // at https://design-system.service.gov.uk/components/cookie-banner/
        showCookieBanner();
    }

    const acceptAllCookiesButton = $('[name="accept-all-cookies"]');
    const rejectAllCookiesButton = $('[name="reject-all-cookies"]');

    [acceptAllCookiesButton, rejectAllCookiesButton].forEach(button => {
        button.click(function () {
            const allow = button.attr("name") === "accept-all-cookies";
            updateAnalyticsCookies(allow);
            setCookie('cookie_preferences_set', 'true', 365);
            hideCookiesQuestion();
            showConfirmationMessage(allow);
        });
    });

    const hideConfirmationMessageButton = $('[name="hide-cookies-confirmation"]')
    hideConfirmationMessageButton.click(() => {
        hideConfirmationMessage();
        hideCookieBanner();
    });
}

exports.cookiesPage = function (
    cookiesUpdatedRedirectPath
) {
    setFormDefaults();

    const cookiesForm = $('#cookies-form');

    cookiesForm.submit(function (event) {
        event.preventDefault();
        const allowAnalyticsCookies = $('[name=analytics-cookies]:checked').val() === 'on';
        updateAnalyticsCookies(allowAnalyticsCookies);
        setCookie('cookie_preferences_set', 'true', 365);
        hideCookieBanner();
        window.location.href = cookiesUpdatedRedirectPath
    });
    cookiesForm.removeClass('hide');
    $('#no-js-cookie-explanation').addClass('hide');
}

function updateAnalyticsCookies(allow) {
    setCookie('allow_analytics_cookies', allow, 365);
    if (!allow) {
        deleteCookie('_ga');
        deleteCookie('_gat');
        deleteCookie('_gid');
    } else {
        // defined in layout.njk
        if (window.setupGATracking) {
            window.setupGATracking();
        } else {
            console.error("Missing global function setupGATracking from layout.njk")
        }
    }
}

function setFormDefaults() {
    const analyticsCookie = getCookie('allow_analytics_cookies');
    if (analyticsCookie === 'true') {
        $('#allow-analytics-cookies').prop("checked", true);
    } else {
        $('#disallow-analytics-cookies').prop("checked", true);
    }
}

// Cookie helper code adapted from https://stackoverflow.com/a/24103596
function setCookie(name,value,days) {
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
}

function deleteCookie(name) {
    document.cookie = name+'=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
}

function getCookie(name) {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(';');

    return getFirstDefined(
        cookies.map(getValueIfCorrectCookie(nameEQ))
    );
}

function showCookieBanner() {
    const cookieBanner = document.querySelector(".govuk-cookie-banner");
    cookieBanner.removeAttribute("hidden");
}

function hideCookieBanner() {
    const cookieBanner = document.querySelector(".govuk-cookie-banner");
    cookieBanner.setAttribute("hidden", "");
}

function hideCookiesQuestion() {
    const cookieBanner = document.querySelector(".reject-or-accept-cookies");
    cookieBanner.setAttribute("hidden", "");
}

function showConfirmationMessage(allow) {
    const confirmationMessageClass = allow ? ".accept-cookies-confirmation-message" : ".reject-cookies-confirmation-message";
    const confirmationMessage = document.querySelector(confirmationMessageClass);
    // See GDS guidance under 'Option 3' at https://design-system.service.gov.uk/components/cookie-banner/
    confirmationMessage.removeAttribute("hidden");
    confirmationMessage.tabIndex = -1;
    confirmationMessage.role = "alert";
    confirmationMessage.focus();
}

function hideConfirmationMessage() {
    const rejectedConfirmationMessage = document.querySelector(".reject-cookies-confirmation-message");
    const acceptedConfirmationMessage = document.querySelector(".accept-cookies-confirmation-message");
    [rejectedConfirmationMessage, acceptedConfirmationMessage].forEach(message => {
        message.setAttribute("hidden", "");
    });
}

function or(x,y) {
    return x || y;
}

function getValueIfCorrectCookie(nameEQ) {
    return function(cookie) {
        const index = cookie.indexOf(nameEQ);
        if (index !== -1) {
            return cookie.substring(nameEQ.length + index, cookie.length);
        }
    }
}

function getFirstDefined(arr) {
    return arr.reduce(or)
}
