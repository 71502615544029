
// Given a URL for an Object in S3, extracts the "bucket" and "key" values,
// which are required for various S3 API ops.
// The key is url encoded by S3, including "/" chars, when it is used in the
// contexts we are interested in.
// S3 is sometimes inconsistent in this, and will frequently not escape some
// chars like "/" in S3 keys in some contexts. If "/" is not escaped in the
// URL, then this function will cope fine.
exports.urlToBucketKey = function (url) {
    // Most common URL type: the bucket is shown as a sub-domain of the S3 hostname.
    // The ignored part of the hostname is the AWS region, e.g. "eu-west-2".
    const bucketHostRe = new RegExp("^https://([^./]+).s3.[^./]+.amazonaws.com/(.+)$");
    let m = bucketHostRe.exec(url);
    if (m) {
        return {bucket: m[1], key: s3KeyUrlDecode(m[2])};
    }

    // Less commonly, the bucket may appear as a dirname in a S3 hostname.
    // This style is used by our local mock S3 server during local dev, so we
    // need to support it here for local dev to work.
    const bucketPathRe = new RegExp("^https?://[^/]+/([^/]+)/(.+)$");
    m = bucketPathRe.exec(url)
    if (m) {
        return {bucket: m[1], key: s3KeyUrlDecode(m[2])};
    }

    throw new Error("Unable to parse url as an S3 object URL");
}

function s3KeyUrlDecode(encodedKey) {
    return decodeURIComponent(encodedKey.replaceAll("+", " "));
}
