exports.duplicateApplication = function() {
    $("#exit-button").click(function() {
        sendGAEvent("Exited after duplicate warning");
    });

    $("#continue-button").click(function() {
        if ($("#resubmit").is(":checked")) {
            sendGAEvent("Submitted application after duplicate warning");
        }
    });

    $('input[name="js-enabled"]').val("true");
};

function sendGAEvent(eventName) {
    ga && ga('send', {
        hitType: 'event',
        eventCategory: '/duplicate-application',
        eventAction: eventName,
    });
    window.dataLayer && dataLayer.push({
        'event': 'event_data',
        'event_data': {
            'event_name': 'duplicate_application'
        },
    })
}