exports.gaEventValidationError = function(errors) {
    const endpoint = window.location.pathname;
    Object.keys(errors).map(error => {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Validation error',
            eventAction: `${endpoint} - ${error}`,
            eventLabel: errors[error].text || errors[error].html
        })
        window.dataLayer && dataLayer.push({
            'event': 'validation_error',
            'event_data': {
                'event_name': 'validation_error',
                'error_category': error,
                'error_text': errors[error].text,
                'error_html': errors[error].html,
            },
        })
    })
};

exports.gaEventBackLink = function() {
    $('.govuk-back-link').click(function() {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Back link clicked',
            eventAction: 'Back link clicked',
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': 'back_link_clicked'
            },
        })
    });
};

exports.gaEventExitPage = function(exit) {
    ga && ga('send', {
        hitType: 'event',
        eventCategory: 'Kick out',
        eventAction: exit,
    });
    window.dataLayer && dataLayer.push({
        'event': 'event_data',
        'event_data': {
            'event_name': 'kick_out'
        },
    })
};

exports.gaEventOfflineFormDownload = function() {
    $('.offline-form-link').click(function() {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Offline form downloaded',
            eventAction: 'Offline form downloaded',
            eventLabel: window.location.pathname
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': 'offline_form_all'
            },
        })
    });
}

exports.gaEventOfflineFormDownloadVoluntary = function() {
    $('.govuk-details .offline-form-link').click(function() {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Offline form downloaded',
            eventAction: 'Offline form downloaded - voluntary exit',
            eventLabel: window.location.pathname
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': 'offline_form_voluntary'
            },
        })
    });
}

exports.gaEventContactLocalElectoralRegistrationOffice = function() {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Link click',
            eventAction: 'Contact local Electoral Registration Office',
            eventLabel: window.location.pathname
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': 'contact_ero'
            },
        })
}


exports.gaEventAnythingWrongWithPageClick = function() {
    $('#anything-wrong-with-page').click(function() {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Link click',
            eventAction: 'Is there anything wrong with this page?',
            eventLabel: window.location.pathname
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': 'anything_wrong_with_page_click'
            },
        })
    });
}

exports.gaEventSubmitButtonClick = function() {
    $('button[type="submit"]').click(function() {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Form submission',
            eventAction: 'Page submit click',
            eventLabel: window.location.pathname
        });
        window.dataLayer && dataLayer.push({
            'event': 'event_data',
            'event_data': {
                'event_name': 'page_submission'
            },
        })
    });
}

exports.gaEventErrorPage = function () {
    ga && ga('send', {
        hitType: 'event',
        eventCategory: 'Error page',
        eventAction: 'Error page',
        eventLabel: window.location.pathname
    });
    window.dataLayer && dataLayer.push({
        'event': 'event_data',
        'event_data': {
            'event_name': 'error_page'
        },
    })
}